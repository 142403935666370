import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const Currency = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Currency - Gold App Admin Portal`;
  }, []);

  const [attributes] = useState([
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Value",
      name: "value",
      validation: "",
      default: "",
      tag: true,
      label: "Value",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Bid Conversion Rate",
      name: "bidConversionRate",
      validation: "",
      default: "",
      tag: true,
      label: "Bid Conversion Rate",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Ask Conversion Rate",
      name: "askConversionRate",
      validation: "",
      default: "",
      tag: true,
      label: "Ask Conversion Rate",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Currency Shot",
      name: "currencyShot",
      validation: "",
      default: "",
      tag: true,
      label: "Currency Shot",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  return (
    <Container className="noshadow">
      <ListTable
        // actions={actions}
        api={`currency`}
        itemTitle={{
          name: "title",
          type: "text",
          collection: "",
        }}
        shortName={`Currency`}
        formMode={`single`}
        attributes={attributes}
        {...props}
      ></ListTable>
    </Container>
  );
};

export default Layout(Currency);
