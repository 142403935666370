import React from "react";
import Menu from "../pages/menu/index.js";
import Franchise from "../pages/franchise/index.js";
import Login from "../../public/login/index.js";
import Page404 from "../pages/page404/index.js";
import UserType from "../pages/user/userType/index.js";
import UserList from "../pages/user/userList/index.js";
import Dashboard from "../pages/dashboard/index.js";
import Admin from "../pages/franchise/admin.js";
import Banner from "../pages/banner/index.js";
import Currency from "../pages/currency/index.js";
import CustomerType from "../pages/customerType/index.js";
import FranchiseProfile from "../pages/franchiseProfile/index.js";
import InterestedToBuy from "../pages/interestedToBuy/index.js";
import Notification from "../pages/notification/index.js";
import Product from "../pages/products/product/index.js";
import ProductDetails from "../pages/products/prodcutDetails/index.js";
import Spread from "../pages/spread/index.js";
import Configuration from "../pages/franchise/configuration.js";

const RenderPage = (page, key, user, privileges) => {
  switch (page) {
    case "login":
      return <Login key={key} />;
    case "menu":
      return <Menu key={key} {...privileges} />;
    case "franchise":
      return <Franchise key={key} {...privileges} />;
    case "user-role":
      return <UserType key={key} {...privileges} />;
    case "user-list":
      return <UserList key={key} {...privileges} />;
    case "dashboard":
      return <Dashboard key={key} {...privileges} />;
    case "admin":
      return <Admin key={key} {...privileges} />;
    case "banner":
      return <Banner key={key} {...privileges} />;
    case "currency":
      return <Currency key={key} {...privileges} />;
    case "customer-type":
      return <CustomerType key={key} {...privileges} />;
    case "franchiseprofile":
      return <FranchiseProfile key={key} {...privileges} />;
    case "interestedtobuy":
      return <InterestedToBuy key={key} {...privileges} />;
    case "notififcation":
      return <Notification key={key} {...privileges} />;
    case "product":
      return <Product key={key} {...privileges} />;
    case "product-details":
      return <ProductDetails key={key} {...privileges} />;
    case "spread":
      return <Spread key={key} {...privileges} />;
    case "configuration":
      return <Configuration key={key} {...privileges} />;
    default:
      return <Page404 key={key}></Page404>;
  }
};

export default RenderPage;
