import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const InterestedToBuy = (props) => {
    //to update the page title
    useEffect(() => {
        document.title = `Interested To Buy - Gold App Admin Portal`;
    }, []);

    const [attributes] = useState([
        {
            type: "select",
            apiType: "API",
            selectApi: "product/select",
            placeholder: "Product",
            name: "product",
            validation: "",
            showItem: "title",
            default: "",
            label: "Product",
            tag: true,
            required: true,
            view: true,
            add: true,
            update: true,
            filter: false,
        },
        // {
        //   type: "select",
        //   apiType: "API",
        //   selectApi: "user/select",
        //   placeholder: "User",
        //   name: "user",
        //   validation: "",
        //   showItem: "",
        //   default: "",
        //   label: "User",
        //   tag: true,
        //   required: true,
        //   view: false,
        //   add: true,
        //   update: true,
        //   filter: false,
        // },
    ]);

    return (
        <Container className="noshadow">
            <ListTable
                // actions={actions}
                api={`interestedtobuy`}
                itemTitle={{
                    name: "product",
                    type: "text",
                    collection: "",
                }}
                shortName={`Interested To Buy`}
                formMode={`single`}
                attributes={attributes}
                {...props}
            ></ListTable>
        </Container>
    );
};

export default Layout(InterestedToBuy);