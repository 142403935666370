import React, { useCallback, useEffect, useState } from "react";
import withLayout from "../../../core/layout";
import io from "socket.io-client";
import { Count, DashboardSection, IconWrapper, MarDiv, ScrollLayout, TabButton, TabContainer, Table, Tile, Title, TitleBox } from "./styles";
import { GetIcon } from "../../../../icons";
import { RowContainer } from "../../../styles/containers/styles";
import { getData, putData } from "../../../../backend/api";

const Dashboard = (props) => {
  // const [priceData, setPriceData] = useState([]);
  const [gold, setGold] = useState(0);
  const [bidGold, setBidGold] = useState(0);
  const [silver, setSilver] = useState(0);
  const [goldColor, setGoldColor] = useState("black");
  const [goldBidColor, setBidGoldColor] = useState("black");
  const [silverBidColor, setSilverBidColor] = useState("black");
  const [silverBid, setSilverBid] = useState(0);
  const [silverColor, setSilverColor] = useState("black");
  const [high, setHigh] = useState(0);
  const [low, setLow] = useState(0);
  const [highSilver, setHighSilver] = useState(0);
  const [lowSilver, setLowSilver] = useState(0);
  const [market, setMarket] = useState(null);
  const handleData = useCallback(
    (newPriceData) => {
      if (newPriceData?.["XAUUSD"]) {
        const goldAsk = parseFloat(newPriceData?.["XAUUSD"]?.ask);
        const goldBid = parseFloat(newPriceData?.["XAUUSD"]?.bid);

        const silverAsk = parseFloat(newPriceData?.["XAGUSD"]?.ask);
        const silverBid = parseFloat(newPriceData?.["XAGUSD"]?.bid);

        setGold((gold) => {
          if (gold === goldAsk) {
            setGoldColor("black");
          } else if (gold > goldAsk) {
            setGoldColor("red");
          } else {
            setGoldColor("green");
          }
          return goldAsk;
        });

        setBidGold((bidGold) => {
          if (bidGold === goldBid) {
            setBidGoldColor("black");
          } else if (bidGold > goldBid) {
            setBidGoldColor("red");
          } else {
            setBidGoldColor("green");
          }
          return goldBid;
        });

        setSilver((silver) => {
          if (silver === silverAsk) {
            setSilverColor("black");
          } else if (silver > silverAsk) {
            setSilverColor("red");
          } else {
            setSilverColor("green");
          }
          return silverAsk;
        });

        setSilverBid((bidSilver) => {
          if (bidSilver === silverBid) {
            setSilverBidColor("black");
          } else if (bidSilver > silverBid) {
            setSilverBidColor("red");
          } else {
            setSilverBidColor("green");
          }
          return silverBid;
        });
        setHigh(newPriceData?.["XAUUSD"]?.high);
        setLow(newPriceData?.["XAUUSD"]?.low);
        setHighSilver(newPriceData?.["XAGUSD"]?.high);
        setLowSilver(newPriceData?.["XAGUSD"]?.low);
        setMarket(newPriceData?.MARKET);
      }
    },
    [setHighSilver, setLowSilver, setGold, setGoldColor, setBidGold, setBidGoldColor, setSilver, setSilverColor, setSilverBid, setSilverBidColor, setLow, setHigh]
  );

  const [data, setData] = useState(null);
  const [dataBack, setDataBack] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [isEditIndex, setIsEditedIndex] = useState(0);
  // const [dollarRate, setDollarRate] = useState(1);
  // const [dollarRateText, setDollarRateText] = useState("USD");
  useEffect(() => {
    if (!data) {
      getData({}, "currency/user").then((data) => {
        setData(JSON.parse(JSON.stringify(data.data)));
        setDataBack(JSON.parse(JSON.stringify(data.data)));

        setUserType(data.data?.userType?.[0]?._id);
      });
    }
  }, [data]);

  useEffect(() => {
    console.log("triggered");
    const login = JSON.parse(localStorage.getItem("user"));
    let replacedUrl = process.env.REACT_APP_API.replace("https://", "wss://").replace("http://", "ws://").replace("/api/v1/", "");
    const socketURI = replacedUrl + "?franchise=" + login.user.franchise + "&customerType=65782bcc050714614956719e";
    const socket = io(socketURI, {
      reconnection: true,
      reconnectionAttempts: 5, // Adjust as needed
    });

    let heartbeatInterval;

    socket.on("connect", () => {
      console.log("socket connected");

      // Send a heartbeat every 30 seconds (adjust as needed)
      // heartbeatInterval = setTimeout(() => {
      //   socket.disconnect();
      //   window.location.reload();
      // }, 60000);
    });

    socket.on("disconnect", (reason) => {
      console.log("socket disconnected:", reason);

      // Clear the heartbeat interval on disconnect
      clearInterval(heartbeatInterval);
    });

    socket.on("connect_error", (error) => {
      console.error("error connecting to socket:", error);

      // Clear the heartbeat interval on connect error
      clearInterval(heartbeatInterval);
    });

    socket.on("message", (prices) => {
      handleData(prices);
    });

    return () => {
      clearInterval(heartbeatInterval);
      socket.disconnect();
    };
  }, [handleData]); // Empty dependency array ensures this effect runs only once on mount
  const checkIsNumber = (value) => {
    if (typeof value === "number" && !isNaN(value)) {
      return value;
    } else {
      return 0;
    }
  };
  const updateData = (spread, index, column) => {
    // Assuming 'data' is your state and 'userType' is a property of 'data'
    const isValidSpread = !isNaN(parseFloat(spread));

    const tempData = { ...data };
    // Assuming 'userType' is an array within 'data'
    tempData.userType[index]["speadInfo"][column] = parseFloat(spread);
    // Assuming 'setUserType' is the state-setting function
    if (isValidSpread) {
      setIsEdited(true);
    } else {
      setIsEdited(false);
    }
    setData(tempData);
    setIsEditedIndex(index);
    console.log({ data, dataBack });
  };
  const appyChanges = async (index) => {
    try {
      const tempData = { ...data };
      const save = tempData.userType[index]["speadInfo"];

      const res = await putData({ id: save._id, ...save, franchiseInfo: 0 }, `spread`);
      if (res.status === 200) {
        setDataBack(JSON.parse(JSON.stringify(data)));
        setIsEdited(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const revertChanges = () => {
    console.log(dataBack);
    setData(JSON.parse(JSON.stringify(dataBack)));
    setIsEdited(false);
  };
  function formatNumber(number, decimalPlaces = 2) {
    return number.toLocaleString("en-US", {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
  }

  const [userType, setUserType] = useState(data?.userType?.[0]?._id ?? null);

  return (
    <RowContainer>
      <DashboardSection className="nomarg">
        {market && (
          <Tile>
            <TitleBox>
              <Count>{market === true ? "Market Opened" : "Market Closed"}</Count>
            </TitleBox>
            <IconWrapper style={{ background: "#f3f3f3", color: market === true ? "green" : "red" }}>
              <GetIcon icon={true ? "time" : "close"} />
            </IconWrapper>
          </Tile>
        )}
        <Tile>
          <TitleBox>
            <Count>${formatNumber(bidGold)}</Count>
            <Title>{`GOLD BID`}</Title>
            <Title>{`LOWEST : $${low}`}</Title>
          </TitleBox>
          <IconWrapper style={{ background: "#f3f3f3", color: goldBidColor }}>
            <GetIcon icon={goldBidColor === "red" ? "low" : "high"} />
          </IconWrapper>
        </Tile>
        <Tile>
          <TitleBox>
            <Count>${formatNumber(gold)}</Count>
            <Title>{`GOLD ASK`}</Title>
            <Title>{`HIGHEST : $${high}`}</Title>
          </TitleBox>
          <IconWrapper style={{ background: "#f3f3f3", color: goldColor }}>
            <GetIcon icon={goldColor === "red" ? "low" : "high"} />
          </IconWrapper>
        </Tile>
        <Tile>
          <TitleBox>
            <Count>${formatNumber(silverBid)}</Count>
            <Title>{`SILVER BID`}</Title>
            <Title>{`LOWEST : $${lowSilver}`}</Title>
          </TitleBox>
          <IconWrapper style={{ background: "#f3f3f3", color: silverBidColor }}>
            <GetIcon icon={silverBidColor === "red" ? "low" : "high"} />
          </IconWrapper>
        </Tile>
        <Tile>
          <TitleBox>
            <Count>${formatNumber(silver)}</Count>
            <Title>{`SILVER ASK`}</Title>
            <Title>{`HIGHEST : $${highSilver}`}</Title>
          </TitleBox>
          <IconWrapper style={{ background: "#f3f3f3", color: silverColor }}>
            <GetIcon icon={silverColor === "red" ? "low" : "high"} />
          </IconWrapper>
        </Tile>
      </DashboardSection>
      <MarDiv>
        {data?.userType?.length > 1 && (
          <TabContainer>
            {data?.userType?.map((user, index) => (
              <TabButton key={index} className={userType === user._id} onClick={() => setUserType(user._id)}>
                {user.title}
              </TabButton>
            ))}
          </TabContainer>
        )}
        <ScrollLayout>
          {data &&
            data?.userType?.map((user, index) => {
              const Gold995 = 31.98999895;
              const Gold9999 = 32.15074;
              const TTBar = 3.74631263;
              const OneGram = 0.03215074;
              const Silver = 0.03215074 * 1000;
              const currency = user.speadInfo.franchiseInfo.currency;

              const askGoldPrice = gold + user.speadInfo.goldAskAdjustment + user.speadInfo.goldAskSpread;
              const bidGoldPrice = bidGold - user.speadInfo.goldBidAdjustment - user.speadInfo.goldBidSpread;
              const MarketSpread = askGoldPrice - bidGoldPrice;

              const askSilverPrice = silver + user.speadInfo.silverAskAdjustment + user.speadInfo.silverAskSpread;
              const bidSilverPrice = silverBid - user.speadInfo.silverBidAdjustment - user.speadInfo.silverBidSpread;
              // const SilverMarketSpread = askSilverPrice - bidSilverPrice;

              return (
                userType === user._id && (
                  <Table key={index}>
                    <tbody>
                      {data?.currency?.map((item, index) => {
                        const askConversionRate = item.askConversionRate;
                        const bidConversionRate = item.bidConversionRate;
                        return item.value === currency.value ? (
                          <>
                            <tr>
                              <th colSpan={1} className="bold">
                                {item.currency}
                              </th>
                              <th colSpan={2}>
                                Final Rate <i>({`${MarketSpread.toFixed(2)}`})</i>
                              </th>
                              <th>Gold 995 1Kg</th>
                              <th>Gold 9999 1Kg</th>
                              <th>Gold TT Bar</th>
                              <th>Gold 9999 1Gm</th>
                              <th>Silver 1Kg</th>
                            </tr>
                            <tr style={{ color: goldBidColor }}>
                              <th style={{ color: "black" }} className="bold">
                                <div className="two">
                                  <span>Bid</span>
                                  <span>
                                    $1({bidConversionRate}){item.currencyShot}
                                  </span>
                                </div>
                              </th>
                              <td colSpan={2} className="bold">
                                ${formatNumber(bidGoldPrice, item.decimal)}
                              </td>
                              <td className="bold">{formatNumber(bidConversionRate * Gold995 * (bidGoldPrice + checkIsNumber(user.speadInfo.bidGold995Kg)), item.decimal)}</td>
                              <td className="bold">{formatNumber(bidConversionRate * Gold9999 * (bidGoldPrice + checkIsNumber(user.speadInfo.bidGold9999Kg)), item.decimal)}</td>
                              <td className="bold">{formatNumber(bidConversionRate * TTBar * (bidGoldPrice + checkIsNumber(user.speadInfo.bidGoldTTBar)), item.decimal)}</td>
                              <td className="bold">{formatNumber(bidConversionRate * OneGram * (bidGoldPrice + checkIsNumber(user.speadInfo.bidGold9999Gm)), 3)}</td>
                              <td className="bold" style={{ color: silverColor }}>
                                <div className="two">
                                  <span>{formatNumber(bidConversionRate * Silver * (bidSilverPrice + checkIsNumber(user.speadInfo.bidSilverKg)), item.decimal)}</span>
                                  <span>${formatNumber(Silver * bidSilverPrice, item.decimal)}</span>
                                </div>
                              </td>
                            </tr>
                            <tr style={{ color: goldColor }}>
                              <th style={{ color: "black" }} className="bold">
                                <div className="two">
                                  <span>Ask</span>
                                  <span>
                                    $1({askConversionRate}){item.currencyShot}
                                  </span>
                                </div>
                              </th>
                              <td colSpan={2} className="bold">
                                ${formatNumber(askGoldPrice, item.decimal)}
                              </td>
                              <td className="bold">{formatNumber(askConversionRate * Gold995 * (askGoldPrice + checkIsNumber(user.speadInfo.askGold995Kg)), item.decimal)}</td>
                              <td className="bold">{formatNumber(askConversionRate * Gold9999 * (askGoldPrice + checkIsNumber(user.speadInfo.askGold9999Kg)), item.decimal)}</td>
                              <td className="bold">{formatNumber(askConversionRate * TTBar * (askGoldPrice + checkIsNumber(user.speadInfo.askGoldTTBar)), item.decimal)}</td>
                              <td className="bold">{formatNumber(askConversionRate * OneGram * (askGoldPrice + checkIsNumber(user.speadInfo.askGold9999Gm)), 3)}</td>
                              <td className="bold" style={{ color: silverColor }}>
                                <div className="two">
                                  <span>{formatNumber(askConversionRate * Silver * (askSilverPrice + checkIsNumber(user.speadInfo.askSilverKg)), item.decimal)}</span>
                                  <span>${formatNumber(Silver * askSilverPrice, item.decimal)}</span>
                                </div>
                              </td>
                            </tr>
                          </>
                        ) : (
                          <div></div>
                        );
                      })}
                      <tr>
                        <td>Adjustments ($USD)</td>
                        <th colSpan={1} className="bold">
                          Spread Value
                        </th>
                        <th colSpan={6} className="bold">
                          Premium(+) / Discounts(-)
                        </th>
                      </tr>

                      <tr>
                        <th className="bold"> Bid</th>
                        <td colSpan={2}>
                          <input type="number" onChange={(event) => updateData(event.target.value, index, "goldBidSpread")} value={user.speadInfo.goldBidSpread} />
                          {/* <input type="number" onChange={(event) => updateData(event.target.value, index, "goldAdjustSpread")} value={user.speadInfo.goldAdjustSpread} /> */}
                        </td>

                        <td>
                          <input type="number" onChange={(event) => updateData(event.target.value, index, "bidGold995Kg")} value={user.speadInfo["bidGold995Kg"]} />
                        </td>
                        <td>
                          <input type="number" onChange={(event) => updateData(event.target.value, index, "bidGold9999Kg")} value={user.speadInfo["bidGold9999Kg"]} />
                        </td>
                        <td>
                          <input type="number" onChange={(event) => updateData(event.target.value, index, "bidGoldTTBar")} value={user.speadInfo.bidGoldTTBar} />
                        </td>
                        <td>
                          <input type="number" onChange={(event) => updateData(event.target.value, index, "bidGold9999Gm")} value={user.speadInfo.bidGold9999Gm} />
                        </td>
                        <td>
                          <input type="number" onChange={(event) => updateData(event.target.value, index, "bidSilverKg")} value={user.speadInfo.bidSilverKg} />
                        </td>
                      </tr>
                      <tr>
                        <th className="bold">Ask</th>
                        <td colSpan={2}>
                          <input type="number" onChange={(event) => updateData(event.target.value, index, "goldAskSpread")} value={user.speadInfo.goldAskSpread} />
                          {/* Silver $ <input type="number" onChange={(event) => updateData(event.target.value, index, "silverSpread")} value={user.speadInfo.silverSpread} /> */}
                          {/* <input type="number" onChange={(event) => updateData(event.target.value, index, "silverAdjustSpread")} value={user.speadInfo.silverAdjustSpread} /> */}
                          {/* <i>({`${SilverMarketSpread.toFixed(2)}`})</i> */}
                        </td>

                        <td>
                          <input type="number" onChange={(event) => updateData(event.target.value, index, "askGold995Kg")} value={user.speadInfo["askGold995Kg"]} />
                        </td>
                        <td>
                          <input type="number" onChange={(event) => updateData(event.target.value, index, "askGold9999Kg")} value={user.speadInfo["askGold9999Kg"]} />
                        </td>
                        <td>
                          <input type="number" onChange={(event) => updateData(event.target.value, index, "askGoldTTBar")} value={user.speadInfo.askGoldTTBar} />
                        </td>
                        <td>
                          <input type="number" onChange={(event) => updateData(event.target.value, index, "askGold9999Gm")} value={user.speadInfo.askGold9999Gm} />
                        </td>
                        <td>
                          <input type="number" onChange={(event) => updateData(event.target.value, index, "askSilverKg")} value={user.speadInfo.askSilverKg} />
                        </td>
                      </tr>
                      {isEdited && index === isEditIndex && (
                        <tr style={{ color: "red" }}>
                          <td colSpan={8} className="button">
                            <div className="message">
                              You have updated the values, please apply the changes
                              <button style={{ backgroundColor: "red", color: "white" }} onClick={() => revertChanges()}>
                                <GetIcon icon={"close"}></GetIcon>
                              </button>
                              <button style={{ backgroundColor: "green", color: "white" }} onClick={() => appyChanges(index)}>
                                <GetIcon icon={"checked"}></GetIcon>
                              </button>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                )
              );
            })}
        </ScrollLayout>
      </MarDiv>
    </RowContainer>
  );
};

export default withLayout(Dashboard);
