import styled, { keyframes } from "styled-components";
// Container for the dashboard section

// Container for the dashboard section

export const DashboardSection = styled.div`
  margin: 30px;
  display: grid;
  width: 100%;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, 200px);
  height: auto;
  align-content: flex-start;
  @media screen and (max-width: 560px) {
    grid-template-columns: auto;
    width: auto;
  }
`;

export const Tile = styled.div`
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 2px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  margin-bottom: auto;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
`;
export const TitleBox = styled.div`
  margin-top: 0;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-direction: column;
`;
export const Title = styled.span`
  font-size: 12px;
  color: gray;
  white-space: nowrap;
`;

export const Count = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const IconWrapper = styled.div`
  font-size: 15px;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  align-self: flex-start;
`;

export const MarDiv = styled.div`
  margin: 0 30px;
  width: calc(100% - 60px);
`;
const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: .50;
  }
  100% {
    opacity: 1;
  }
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 10px;
  margin-left: -10px;
  min-width: 100%;
  th {
    white-space: nowrap;
    min-width: 90px;
    background-color: #fff;
    border-radius: 12px;
    padding: 10px 15px;
    color: inherit;
    text-align: left;
    background: linear-gradient(219deg, rgb(228, 228, 228) 0%, rgb(246 170 65) 35%, rgb(242 183 46) 100%);
  }
  td {
    background-color: #fff;
    border-radius: 12px;
    padding: 10px;
    color: inherit;
    text-align: left;
    white-space: nowrap;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 2px;
    i {
      font-size: 12px;
    }
  }
  td:first-child,
  th:first-child {
    text-align: left;
  }
  td.bold {
    font-weight: bold;
    color: #000;
  }
  td .two,
  th .two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span:last-child {
      font-size: 10px;
    }
  }
  td .span {
    font-weight: bold;
    color: #545050;
    background: #cdcdcd;
    border-radius: 50%;
    padding: 4px;
    font-size: 12px;
    margin-right: 7px;
  }
  .button {
    background-color: transparent;
  }
  td button {
    font-weight: bold;
    color: #545050;
    background: white;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    padding: 10px;
    font-size: 15px;
    margin-right: 0px;
    outline: none;
    border: 1px solid lightgray;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  input {
    width: calc(100% - 10px);
    border: 0px;
    outline: 0px;
    font-size: 16px;
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 12px;
    margin-right: 5px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .message {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: bold;
    animation: ${blinkAnimation} 1s infinite; /* Add this line for the blinking effect */
    margin: -10px;
    background: white;
    border: 1px solid;
    border-radius: 10px;
    padding: 5px;
  }
`;
export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0;
  z-index: 1;
`;
export const TabButton = styled.button`
  padding: 10px 20px;
  background-color: white;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 2px;
  &:first-child {
    border-radius: 10px 0 0 10px;
  }
  &:last-child {
    border-radius: 0 10px 10px 0;
  }
  :first-child:last-child {
    border-radius: 10px;
  }
  &.true {
    background-color: #ccc;
    font-weight: normal;
    font-weight: bold;
  }
  border: none;
  font-size: 14px;
  margin-bottom: 0px;
  &.nomargin {
    margin: 0;
  }
  cursor: pointer;
`;
export const ScrollLayout = styled.div`
  display: flex;
  max-width: 100%;
  td:first-child,
  th:first-child {
    position: sticky;
    left: 0;
  }
  @media screen and (max-width: 768px) {
    overflow: auto;
  }
`;
