import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const Franchise = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Franchise - Gold App Admin Portal`;
  }, []);

  const [attributes] = useState([
    {
      type: "text",
      placeholder: "Franchise Name",
      name: "name",
      validation: "",
      default: "",
      label: "Franchise Name",
      required: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Location",
      name: "location",
      validation: "",
      default: "",
      tag: true,
      label: "Location",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Email",
      name: "email",
      validation: "",
      default: "",
      tag: true,
      label: "Email",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Phone",
      name: "phone",
      validation: "",
      default: "",
      tag: true,
      label: "Phone",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Contact Name",
      name: "contactName",
      validation: "",
      default: "",
      tag: true,
      label: "Contact Name",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Franchise Code",
      name: "franchiseCode",
      validation: "",
      default: "",
      tag: true,
      label: "Franchise Code",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Mobile Colors",
      name: "bmr",
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Primary Color",
      name: "primaryColor",
      validation: "",
      default: "",
      tag: true,
      label: "Primary Color",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Secondary Color",
      name: "secondaryColor",
      validation: "",
      default: "",
      tag: true,
      label: "Secondary Color",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Primary Text Color",
      name: "textPrimaryColor",
      validation: "",
      default: "",
      tag: true,
      label: "Primary Text Color",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Secondary Text Color",
      name: "textSecondaryColor",
      validation: "",
      default: "",
      tag: true,
      label: "Secondary Text Color",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Tertiary Text Color",
      name: "textTertiaryColor",
      validation: "",
      default: "",
      tag: true,
      label: "Tertiary Text Color",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "TV colors",
      name: "bmr",
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Primary Color",
      name: "primaryColorTV",
      validation: "",
      default: "",
      tag: true,
      label: "Primary Color",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Secondary Color",
      name: "secondaryColorTV",
      validation: "",
      default: "",
      tag: true,
      label: "Secondary Color",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Primary Text Color",
      name: "textPrimaryColorTV",
      validation: "",
      default: "",
      tag: true,
      label: "Primary Text Color",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Secondary Text Color",
      name: "textSecondaryColorTV",
      validation: "",
      default: "",
      tag: true,
      label: "Secondary Text Color",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Tertiary Text Color",
      name: "textTertiaryColorTV",
      validation: "",
      default: "",
      tag: true,
      label: "Tertiary Text Color",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "Subscription Date",
      name: "subscriptionDate",
      validation: "",
      default: "",
      tag: true,
      label: "Subscription Date",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    // {
    //   type: "select",
    //   apiType: "API",
    //   selectApi: "franchise/select",
    //   placeholder: "Franchise",
    //   name: "franchise",
    //   validation: "",
    //   showItem: "title",
    //   default: "",
    //   label: "Franchise",
    //   required: true,
    //   view: false,
    //   add: true,
    //   update: true,
    //   filter: false,
    // },
  ]);

  const [configuration] = useState([
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Address",
      name: "address",
      validation: "",
      default: "",
      label: "Address",
      // tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "email",
      placeholder: "Email",
      name: "email",
      validation: "",
      default: "",
      label: "Email",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Mobile",
      name: "mobile",
      validation: "",
      default: "",
      label: "Mobile",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "App Name",
      name: "appName",
      validation: "",
      default: "",
      label: "App Name",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "App Description",
      name: "appDescription",
      validation: "",
      default: "",
      label: "App Description",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "About Title",
      name: "aboutTitle",
      validation: "",
      default: "",
      label: "About Title",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "WhatsApp",
      name: "whatsapp",
      validation: "",
      default: "",
      label: "WhatsApp",
      // tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Contact Person",
      name: "contactPerson",
      validation: "",
      default: "",
      label: "Contact Person",
      // tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Location",
      name: "location",
      validation: "",
      default: "",
      label: "Location",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "FaceBook",
      name: "fb",
      validation: "",
      default: "",
      label: "FaceBook",
      // tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Instagram",
      name: "insta",
      validation: "",
      default: "",
      label: "Instagram",
      // tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "YouTube",
      name: "youtube",
      validation: "",
      default: "",
      label: "YouTube",
      // tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Twitter",
      name: "twitter",
      validation: "",
      default: "",
      label: "Twitter",
      // tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "currency/select",
      placeholder: "Default Currency",
      name: "defaultCurrency",
      validation: "",
      showItem: "value",
      default: "",
      label: "Default Currency",
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "image",
      placeholder: "Logo",
      name: "logo",
      validation: "",
      default: "",
      label: "Logo",
      // tag: true,
      // required: true,
      view: true,
      add: true,
      update: true,
    },
    // {
    //   type: "select",
    //   apiType: "API",
    //   selectApi: "franchise/select",
    //   placeholder: "Franchise",
    //   name: "franchise",
    //   validation: "",
    //   showItem: "",
    //   default: "",
    //   label: "Franchise",
    //   tag: true,
    //   required: true,
    //   view: false,
    //   add: true,
    //   update: true,
    //   filter: false,
    // },
  ]);
  const [spread] = useState([
    {
      type: "select",
      apiType: "API",
      selectApi: "customer-type/select",
      placeholder: "Customer Type",
      name: "customerType",
      validation: "",
      showItem: "title",
      default: "",
      label: "Customer Type",
      required: true,
      view: false,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "title",
      title: "Gold Spread and Adjustment",
      name: "bmr",
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Gold Bid Adjustment",
      name: "goldBidAdjustment",
      validation: "",
      default: "",
      label: "Gold Bid Adjustment",
      required: true,
      customClass: "small",
      view: false,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Gold Bid Spread",
      name: "goldBidSpread",
      validation: "",
      default: "",
      label: "Gold Bid Spread",
      required: true,
      customClass: "small",
      view: false,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Gold Ask Spread",
      name: "goldAskSpread",
      validation: "",
      default: "",
      label: "Gold Ask Spread",
      required: true,
      customClass: "small",
      view: false,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Gold Ask Adjustment",
      name: "goldAskAdjustment",
      validation: "",
      default: "",
      label: "Gold Ask Adjustment",
      required: true,
      customClass: "small",
      view: false,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Silver Spread and Adjustment",
      name: "bmr",
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Silver Bid Adjustment",
      name: "silverBidAdjustment",
      validation: "",
      default: "",
      label: "Silver Bid Adjustment",
      required: true,
      customClass: "small",
      view: false,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Silver Bid Spread",
      name: "silverBidSpread",
      validation: "",
      default: "",
      label: "Silver Bid Spread",
      required: true,
      customClass: "small",
      view: false,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Silver Ask Spread",
      name: "silverAskSpread",
      validation: "",
      default: "",
      label: "Silver Ask Spread",
      required: true,
      customClass: "small",
      view: false,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Silver Ask Adjustment",
      name: "silverAskAdjustment",
      validation: "",
      default: "",
      label: "Silver Ask Adjustment",
      required: true,
      customClass: "small",
      view: false,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Ask Premium(+)/Discounts(-)",
      name: "bmr",
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Ask Gold 995 1Kg",
      name: "askGold995Kg",
      customClass: "small",
      validation: "",
      default: "",
      tag: true,
      label: "Ask Gold 995 1Kg",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Ask Gold 9999 1Kg",
      name: "askGold9999Kg",
      customClass: "small",
      validation: "",
      default: "",
      tag: true,
      label: "Ask Gold 9999 1Kg",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Ask Gold TT Bar",
      name: "askGoldTTBar",
      validation: "",
      customClass: "small",
      default: "",
      tag: true,
      label: "Ask Gold TT Bar",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Ask Gold 9999 Gm",
      name: "askGold9999Gm",
      validation: "",
      customClass: "small",
      default: "",
      tag: true,
      label: "Ask Gold 9999 Gm",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    
    {
      type: "title",
      title: "Bid Premium(+)/Discounts(-)",
      name: "bmr",
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Bid Gold 995 1Kg",
      name: "bidGold995Kg",
      validation: "",
      customClass: "small",
      default: "",
      tag: true,
      label: "Bid Gold 995 1Kg",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Bid Gold 9999 1Kg",
      name: "bidGold9999Kg",
      validation: "",
      customClass: "small",
      default: "",
      tag: true,
      label: "Bid Gold 9999 1Kg",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Bid Gold TT Bar",
      name: "bidGoldTTBar",
      validation: "",
      customClass: "small",
      default: "",
      tag: true,
      label: "Bid Gold TT Bar",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Bid Gold 9999 Gm",
      name: "bidGold9999Gm",
      validation: "",
      customClass: "small",
      default: "",
      tag: true,
      label: "Bid Gold 9999 Gm",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Silver Premium(+)/Discounts(-)",
      name: "bmr",
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Bid Silver 1Kg",
      name: "bidSilverKg",
      validation: "",
      customClass: "small",
      default: "",
      tag: true,
      label: "Bid Silver 1Kg",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Ask Silver 1Kg",
      name: "askSilverKg",
      validation: "",
      customClass: "small",
      default: "",
      tag: true,
      label: "Ask Silver 1Kg",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    // ... (Franchise object is commented out as it's not part of the current display)
  ]);

  const [banner] = useState([
    // {
    //     type: "text",
    //     placeholder: "Title",
    //     name: "title",
    //     validation: "",
    //     default: "",
    //     label: "Title",
    //     required: true,
    //     view: true,
    //     add: true,
    //     update: true,
    // },
    // {
    //     type: "text",
    //     placeholder: "Banner Type",
    //     name: "bannerType",
    //     validation: "",
    //     default: "",
    //     tag: true,
    //     label: "Banner Type",
    //     required: true,
    //     view: true,
    //     add: true,
    //     update: true,
    // },
    {
      type: "image",
      placeholder: "Mobile Image",
      name: "mobileImage",
      validation: "",
      default: "",
      tag: true,
      label: "Mobile Image",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "TV Image",
      name: "tvImage",
      validation: "",
      default: "",
      tag: true,
      label: "TV Image",
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [notification] = useState([
    {
      type: "text",
      placeholder: "Title ",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Description",
      name: "description",
      validation: "",
      default: "",
      tag: true,
      label: "Description",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Image",
      name: "image",
      validation: "",
      default: "",
      tag: true,
      label: "Image",
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [user] = useState([
    {
      type: "text",
      placeholder: "Name",
      name: "name",
      validation: "",
      default: "",
      label: "Name",
      required: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Email",
      name: "email",
      validation: "",
      default: "",
      tag: true,
      label: "Email",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Mobile",
      name: "mobile",
      validation: "",
      default: "",
      tag: true,
      label: "Mobile",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Registration Type",
      name: "registrationType",
      validation: "",
      default: "",
      tag: true,
      label: "Registration Type",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Image",
      name: "image",
      validation: "",
      default: "",
      tag: true,
      label: "Image",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [franchiseAdmin] = useState([
    {
      type: "text",
      placeholder: "User Name",
      name: "name",
      validation: "",
      default: "",
      tag: true,
      label: "User Name",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Name",
      name: "name",
      validation: "",
      default: "",
      tag: true,
      label: "Name",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "email",
      placeholder: "E-Mail",
      name: "email",
      validation: "",
      default: "",
      tag: true,
      label: "E-Mail",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "password",
      placeholder: "Password",
      name: "password",
      validation: "",
      default: "",
      // tag: true,
      label: "Password",
      required: false,
      view: true,
      add: true,
      update: false,
    },
  ]);

  const [actions] = useState([
    {
      element: "button",
      type: "subItem",
      id: "franchiseprofile",
      itemTitle: {
        name: "title",
        type: "text",
        collection: "",
      },
      title: "Configuration",
      attributes: configuration,
      params: {
        api: `franchiseprofile`,
        parentReference: "franchise",
        itemTitle: {
          name: "title",
          type: "text",
          collection: "",
        },
        shortName: "Configuration",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: "double",
      },
    },
    {
      element: "button",
      type: "subList",
      id: "spread",
      itemTitle: {
        name: "title",
        type: "text",
        collection: "customerType",
      },
      exportPrivilege: true,
      title: "Spread",
      attributes: spread,
      params: {
        api: `spread`,
        parentReference: "franchise",
        itemTitle: {
          name: "title",
          type: "text",
          collection: "customerType",
        },
        shortName: "Spread",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: "double",
        exportPrivilege: true,
      },
    },
    {
      element: "button",
      type: "subList",
      id: "banner",
      itemTitle: {
        name: "Banner",
        type: "text",
        collection: "",
      },
      exportPrivilege: true,
      title: "Banner",
      attributes: banner,
      params: {
        api: `banner`,
        parentReference: "franchise",
        itemTitle: {
          name: "Banner",
          type: "text",
          collection: "",
        },
        shortName: "Banner",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: "single",
        exportPrivilege: true,
      },
    },
    {
      element: "button",
      type: "subList",
      id: "notification",
      itemTitle: {
        name: "title",
        type: "text",
        collection: "",
      },
      exportPrivilege: true,
      title: "Notification",
      attributes: notification,
      params: {
        api: `notification`,
        parentReference: "franchise",
        itemTitle: {
          name: "title",
          type: "text",
          collection: "",
        },
        shortName: "Notification",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: "single",
        exportPrivilege: true,
      },
    },
    {
      element: "button",
      type: "subList",
      id: "user",
      itemTitle: {
        name: "name",
        type: "text",
        collection: "",
      },
      exportPrivilege: true,
      title: "Users",
      attributes: user,
      params: {
        api: `user`,
        parentReference: "franchise",
        itemTitle: {
          name: "name",
          type: "text",
          collection: "",
        },
        shortName: "Users",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: "single",
        exportPrivilege: true,
      },
    },
    {
      element: "button",
      type: "subList",
      id: "user/franchiseAdmin",
      itemTitle: {
        name: "title",
        type: "text",
        collection: "",
      },
      exportPrivilege: true,
      title: "Franchise Admin",
      attributes: franchiseAdmin,
      params: {
        api: `user/franchiseAdmin`,
        parentReference: "franchise",
        itemTitle: {
          name: "title",
          type: "text",
          collection: "",
        },
        shortName: "Franchise Admin",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: "double",
        exportPrivilege: true,
      },
    },
  ]);
  return (
    <Container className="noshadow">
      <ListTable
        actions={actions}
        api={`franchise`}
        // itemTitle={`label`}
        itemTitle={{
          name: "name",
          type: "text",
          collection: "",
        }}
        shortName={`Franchise`}
        formMode={`double`}
        attributes={attributes}
        {...props}
      ></ListTable>
    </Container>
  );
};

export default Layout(Franchise);
