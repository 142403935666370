import React, { useEffect, useState } from "react";
import Layout from "../../../../core/layout";
import ListTable from "../../../../core/list/list";
import { Container } from "../../../../core/layout/styels";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const ProductDetails = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Product Details- Gold App Admin Portal`;
  }, []);

  const [attributes] = useState([
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Subtitle",
      name: "subTitle",
      validation: "",
      default: "",
      tag: true,
      label: "Subtitle",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Description",
      name: "description",
      validation: "",
      default: "",
      tag: true,
      label: "Description",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Bar Size Info",
      name: "barSizeInfo",
      validation: "",
      default: "",
      tag: true,
      label: "Bar Size Info",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Specifications",
      name: "specifications",
      validation: "",
      default: "",
      tag: true,
      label: "Specifications",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Packaging",
      name: "packaging",
      validation: "",
      default: "",
      tag: true,
      label: "Packaging",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "product/select",
      placeholder: "Product",
      name: "product",
      validation: "",
      showItem: "title",
      default: "",
      label: "Product",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "image",
      placeholder: "Image 1",
      name: "image1",
      validation: "",
      default: "",
      tag: true,
      label: "Image 1",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Image 2",
      name: "image2",
      validation: "",
      default: "",
      tag: true,
      label: "Image 2",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Image 3",
      name: "image3",
      validation: "",
      default: "",
      tag: true,
      label: "Image 3",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  return (
    <Container className="noshadow">
      <ListTable
        // actions={actions}
        api={`product-details`}
        // itemTitle={`label`}
        itemTitle={{
          name: "title",
          type: "text",
          collection: "",
        }}
        shortName={`Product Details`}
        formMode={`double`}
        attributes={attributes}
        {...props}
      ></ListTable>
    </Container>
  );
};

export default Layout(ProductDetails);
