import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const FranchiseProfile = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Franchise Profile - Gold App Admin Portal`;
  }, []);

  const [attributes] = useState([
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Address",
      name: "address",
      validation: "",
      default: "",
      label: "Address",
      // tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "email",
      placeholder: "Email",
      name: "email",
      validation: "",
      default: "",
      label: "Email",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Mobile",
      name: "mobile",
      validation: "",
      default: "",
      label: "Mobile",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "WhatsApp",
      name: "whatsapp",
      validation: "",
      default: "",
      label: "WhatsApp",
      // tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Contact Person",
      name: "contactPerson",
      validation: "",
      default: "",
      label: "Contact Person",
      // tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Location",
      name: "location",
      validation: "",
      default: "",
      label: "Location",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "FaceBook",
      name: "fb",
      validation: "",
      default: "",
      label: "FaceBook",
      // tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Instagram",
      name: "insta",
      validation: "",
      default: "",
      label: "Instagram",
      // tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "YouTube",
      name: "youtube",
      validation: "",
      default: "",
      label: "YouTube",
      // tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Twitter",
      name: "twitter",
      validation: "",
      default: "",
      label: "Twitter",
      // tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "currency/select",
      placeholder: "Default Currency",
      name: "defaultCurrency",
      validation: "",
      showItem: "value",
      default: "",
      label: "Default Currency",
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "image",
      placeholder: "Logo",
      name: "logo",
      validation: "",
      default: "",
      label: "Logo",
      // tag: true,
      // required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "franchise/select",
      placeholder: "Franchise",
      name: "franchise",
      validation: "",
      showItem: "",
      default: "",
      label: "Franchise",
      tag: true,
      required: true,
      view: false,
      add: true,
      update: true,
      filter: false,
    },
  ]);

  const [spread] = useState([
    {
      type: "number",
      placeholder: "Spread",
      name: "spread",
      validation: "",
      default: "",
      label: "Spread",
      required: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Ask Premium(+)/Discounts(=)",
      name: "bmr",
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "askGold995Kg",
      name: "askGold995Kg",
      validation: "",
      default: "",
      tag: true,
      label: "askGold995Kg",
      required: true,
      view: true,
      add: true,
      update: true,
    },

    {
      type: "number",
      placeholder: "askGold9999Kg",
      name: "askGold9999Kg",
      validation: "",
      default: "",
      tag: true,
      label: "askGold9999Kg",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "askGoldTTBar",
      name: "askGoldTTBar",
      validation: "",
      default: "",
      tag: true,
      label: "askGoldTTBar",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "askGold9999Gm",
      name: "askGold9999Gm",
      validation: "",
      default: "",
      tag: true,
      label: "askGold9999Gm",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "askSilverKg",
      name: "askSilverKg",
      validation: "",
      default: "",
      tag: true,
      label: "askSilverKg",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Bid Premium(+)/Discounts(=)",
      name: "bmr",
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "bidGold995Kg",
      name: "bidGold995Kg",
      validation: "",
      default: "",
      tag: true,
      label: "bidGold995Kg",
      required: true,
      view: true,
      add: true,
      update: true,
    },

    {
      type: "number",
      placeholder: "bidGold9999Kg",
      name: "bidGold9999Kg",
      validation: "",
      default: "",
      tag: true,
      label: "bidGold9999Kg",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "bidGoldTTBar",
      name: "bidGoldTTBar",
      validation: "",
      default: "",
      tag: true,
      label: "bidGoldTTBar",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "bidGold9999Gm",
      name: "bidGold9999Gm",
      validation: "",
      default: "",
      tag: true,
      label: "bidGold9999Gm",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "bidSilverKg",
      name: "bidSilverKg",
      validation: "",
      default: "",
      tag: true,
      label: "bidSilverKg",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "customer-type/select",
      placeholder: "Customer Type",
      name: "customerType",
      validation: "",
      showItem: "title",
      default: "",
      label: "Customer Type",
      required: false,
      view: false,
      add: true,
      update: true,
      filter: false,
    },
    // {
    //   type: "select",
    //   apiType: "API",
    //   selectApi: "franchise/select",
    //   placeholder: "Franchise",
    //   name: "franchise",
    //   validation: "",
    //   showItem: "",
    //   default: "",
    //   label: "Franchise",
    //   required: true,
    //   view: false,
    //   add: true,
    //   update: true,
    //   filter: false,
    // },
  ]);

  const [product] = useState([
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Quantity",
      name: "quantity",
      validation: "",
      default: "",
      tag: true,
      label: "Quantity",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Tag",
      name: "tag",
      validation: "",
      default: "",
      tag: true,
      label: "Tag",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Description",
      name: "description",
      validation: "",
      default: "",
      tag: true,
      label: "Description",
      required: true,
      view: true,
      add: true,
      update: true,
    },

    {
      type: "text",
      placeholder: "Available In",
      name: " availableIn",
      validation: "",
      default: "",
      tag: true,
      label: " Available In",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Specification",
      name: "specification",
      validation: "",
      default: "",
      tag: true,
      label: "Specification",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Packaging",
      name: "packaging",
      validation: "",
      default: "",
      tag: true,
      label: "Packaging",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Image",
      name: "image",
      validation: "",
      default: "",
      tag: true,
      label: "Image",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Available In Image",
      name: " availableInImage",
      validation: "",
      default: "",
      tag: true,
      label: "Available In Image",
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [banner] = useState([
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Banner Type",
      name: "bannerType",
      validation: "",
      default: "",
      tag: true,
      label: "Banner Type",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Image",
      name: "image",
      validation: "",
      default: "",
      tag: true,
      label: "Image",
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [notififcation] = useState([
    {
      type: "text",
      placeholder: "Title ",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Description",
      name: "description",
      validation: "",
      default: "",
      tag: true,
      label: "Description",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Image",
      name: "image",
      validation: "",
      default: "",
      tag: true,
      label: "Image",
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [actions] = useState([
    {
      element: "button",
      type: "subList",
      id: "spread",
      itemTitle: {
        name: "commonbuySpread",
        type: "text",
        collection: "",
      },
      exportPrivilege: true,
      title: "Spread",
      attributes: spread,
      params: {
        api: `spread`,
        parentReference: "franchise",
        itemTitle: {
          name: "commonbuySpread",
          type: "text",
          collection: "",
        },
        shortName: "Spread",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: "double",
        exportPrivilege: true,
      },
    },
    {
      element: "button",
      type: "subList",
      id: "product",
      itemTitle: {
        name: "title",
        type: "text",
        collection: "",
      },
      exportPrivilege: true,
      title: "Product",
      attributes: product,
      params: {
        api: `product`,
        parentReference: "",
        itemTitle: {
          name: "title",
          type: "text",
          collection: "",
        },
        shortName: "Product",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: "double",
        exportPrivilege: true,
      },
    },
    {
      element: "button",
      type: "subList",
      id: "banner",
      itemTitle: {
        name: "title",
        type: "text",
        collection: "",
      },
      exportPrivilege: true,
      title: "Banner",
      attributes: banner,
      params: {
        api: `banner`,
        parentReference: "",
        itemTitle: {
          name: "title",
          type: "text",
          collection: "",
        },
        shortName: "Banner",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: "single",
        exportPrivilege: true,
      },
    },
    {
      element: "button",
      type: "subList",
      id: "notififcation",
      itemTitle: {
        name: "title",
        type: "text",
        collection: "",
      },
      exportPrivilege: true,
      title: "Notififcation",
      attributes: notififcation,
      params: {
        api: `notififcation`,
        parentReference: "",
        itemTitle: {
          name: "title",
          type: "text",
          collection: "",
        },
        shortName: "Notififcation",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: "single",
        exportPrivilege: true,
      },
    },
  ]);

  return (
    <Container className="noshadow">
      <ListTable
        actions={actions}
        api={`franchiseprofile`}
        parentReference={`franchise`}
        itemTitle={{
          name: "title",
          type: "text",
          collection: "",
        }}
        shortName={`Franchise Profile`}
        formMode={`double`}
        attributes={attributes}
        {...props}
      ></ListTable>
    </Container>
  );
};

export default Layout(FranchiseProfile);
